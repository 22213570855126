import React, { memo } from 'react';
import connect from 'react/hoc/connectProxy';
import { compose, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import { getCommunities } from 'redux/communities/actions';
import { NAVIGATION_SHORTCUTS_MESSAGES } from 'config/constants.messages';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import CommunitiesListPresentation from 'react/business/communities/list/CommunitiesList';
import connectCommunitiesList from 'react/business/communities/communities.connect';
import TitleHeader from 'react/business/layout/titleHeader/TitleHeader';

import messages from './communities-list.messages';
import classNames from './communities-list.module.scss';

const ConnectedCommunitiesList = connectCommunitiesList(CommunitiesListPresentation);

const enhancer = compose(
  connect(
    null,
    { getCommunities },
  ),

  lifecycle({
    /** List communities with their domains and the count of products of each domain. */
    componentDidMount() {
      this.props.getCommunities({
        populateDomains: true,
        countProducts: true,
        onlyMaintainedOrGlobal: true,
      });
    },
  }),

  memo,
);

const CommunitiesList = () => (
  <ApplicationLayout
    mainClassName={classNames.main}
  >
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <TitleHeader>
      <FormattedMessage {...NAVIGATION_SHORTCUTS_MESSAGES.COMMUNITIES} />
    </TitleHeader>
    <div className={classNames.content}>
      <ConnectedCommunitiesList className={classNames.content} />
    </div>
  </ApplicationLayout>
);

CommunitiesList.displayName = 'CommunitiesList';

export default enhancer(CommunitiesList);
