import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { userShape } from 'shapes/user';

import Avatar from 'react/generic/avatar/Avatar';
import UserName from 'react/generic/users/name/UserName';

import classNames from './leader-bubble.module.scss';

const enhancer = compose(
  memo,
);

const LeaderBubble = ({ user, title }) => (
  <div className={classNames.leader}>
    <Avatar
      src={user.avatar}
      className={classNames.avatar}
      alt="Avatar"
    />
    <div className={classNames.role}>
      {title}
    </div>

    { (user.firstName || user.lastName)
      && (
        <div className={classNames.name}>
          <UserName user={user} />
        </div>
      )}
  </div>
);

LeaderBubble.displayName = 'LeaderBubble';

LeaderBubble.propTypes = {
  user: userShape.isRequired,
  title: PropTypes.node,
};

LeaderBubble.defaultProps = {
  title: null,
};

export default enhancer(LeaderBubble);
