import { compose, withHandlers } from 'recompose';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { formatRoute } from 'react-router-named-routes';

import connect from 'react/hoc/connectProxy';
import {
  selectCommunitiesCount,
  selectCommunitiesIsLoading,
  selectCommunityListWithDomains,
} from 'redux/communities/selectors';
import ROUTES from 'react/routes';

export default compose(
  withRouter,

  withHandlers({
    onClickDomain: ({ history }) => (domainId) => {
      const qs = queryString.stringify({ domainIds: domainId });
      history.push(`${formatRoute(ROUTES.PRODUCTS)}?${qs}`);
    },

    onClickCommunity: ({ history }) => (communityId) => {
      const qs = queryString.stringify({ communityIds: communityId });
      history.push(`${formatRoute(ROUTES.PRODUCTS)}?${qs}`);
    },
  }),

  connect(
    state => ({
      communities: selectCommunityListWithDomains(state),
      totalCount: selectCommunitiesCount(state),
      loading: selectCommunitiesIsLoading(state),
    }),
  ),
);
