import { noop, isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers, branch } from 'recompose';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import cn from 'classnames';

import connect from 'react/hoc/connectProxy';
import { userShape } from 'shapes/user';
import { communityShape } from 'shapes/community';
import { selectUsersByExternalIds } from 'redux/users/selectors';

import { Icon } from 'antd';
import Button from 'react/generic/button/Button';
import SafeHtml from 'react/generic/html/SafeHtml';
import withExternalUserLazyLoading from 'react/business/user/lazyLoad/withExternalUserLazyLoading';
import DomainListItem from './domainListItem/DomainListItem';
import LeaderBubble from './leaderBubble/LeaderBubble';

import messages from './communities-element.messages';
import classNames from './communities-element.module.scss';

const withCommunityLeadersLazyLoading
  = withExternalUserLazyLoading(({
    getIdsToLoadFromProps: ({
      community: {
        businessCommunityLeaderId,
        digitalCommunityLeaderId,
      } = {},
    } = {}) => [
      businessCommunityLeaderId,
      digitalCommunityLeaderId,
    ].filter(Boolean),
  }));

const mapStateToProps = (state, {
  community: {
    businessCommunityLeaderId,
    digitalCommunityLeaderId,
  },
}) => {
  const {
    [businessCommunityLeaderId]: businessCommunityLeader,
    [digitalCommunityLeaderId]: digitalCommunityLeader,
  } = selectUsersByExternalIds(
    state,
    [businessCommunityLeaderId, digitalCommunityLeaderId],
  );
  return {
    businessCommunityLeader,
    digitalCommunityLeader,
  };
};

const withCommunityLeadersUserInfos = connect(mapStateToProps);

const withExpandState = withStateHandlers(
  () => ({
    isDomainsPanelExpanded: false,
    isLeadersPanelExpanded: false,
  }),
  {
    onToggleDomainsPanel: ({ isDomainsPanelExpanded }) => () => ({
      isDomainsPanelExpanded: !isDomainsPanelExpanded,
    }),

    onToggleLeadersPanel: ({ isLeadersPanelExpanded }) => () => ({
      isLeadersPanelExpanded: !isLeadersPanelExpanded,
    }),
  },
);

const enhance = compose(
  injectIntl,
  withExpandState,
  withCommunityLeadersUserInfos,

  // Optimization: only load users if the panel is expanded.
  branch(
    props => props.isLeadersPanelExpanded,
    withCommunityLeadersLazyLoading,
  ),
);

class CommunitiesElement extends React.PureComponent {
  static propTypes = {
    community: communityShape.isRequired,
    isDomainsPanelExpanded: PropTypes.bool,
    isLeadersPanelExpanded: PropTypes.bool,
    onToggleDomainsPanel: PropTypes.func,
    onToggleLeadersPanel: PropTypes.func,
    onClickCommunity: PropTypes.func,
    onClickDomain: PropTypes.func,
    className: PropTypes.string,
    businessCommunityLeader: userShape,
    digitalCommunityLeader: userShape,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    isDomainsPanelExpanded: false,
    isLeadersPanelExpanded: false,
    onToggleDomainsPanel: noop,
    onToggleLeadersPanel: noop,
    onClickCommunity: noop,
    onClickDomain: noop,
    className: null,
    businessCommunityLeader: null,
    digitalCommunityLeader: null,
  };

  onClick = () => {
    const { community: { id } } = this.props;
    this.props.onClickCommunity(id);
  };

  /** @returns {object} JSX. */
  render() {
    const {
      community,
      onClickDomain,
      isDomainsPanelExpanded,
      isLeadersPanelExpanded,
      onToggleDomainsPanel,
      onToggleLeadersPanel,
      className,
      businessCommunityLeader,
      digitalCommunityLeader,
      intl,
    } = this.props;
    const { domains } = community;

    const totalProductCount = domains?.reduce(
      (count, domain) => count + (domain?.productCount || 0),
      0,
    ) || 0;

    const hasDomains = !isEmpty(domains);
    const hasLeaders = community.businessCommunityLeaderId || community.digitalCommunityLeaderId;

    return (
      <div className={cn(className, classNames.card)}>
        <div className={classNames.top}>
          <div className={classNames.title}>
            <button
              type="button"
              className={cn('unstyled with-pointer', classNames.titleButton)}
              onClick={this.onClick}
            >
              {community.name}
            </button>
          </div>

          { community.description
            && (
              <SafeHtml unsafeHtml={community.description} className={classNames.description} />
            ) }

          { hasLeaders
            && (
              <button
                type="button"
                onClick={onToggleLeadersPanel}
                className={cn(
                  'unstyled with-pointer',
                  classNames.leadersButton,
                )}
              >
                <FormattedMessage
                  {...(isLeadersPanelExpanded ?
                    messages.HIDE_LEADERS
                    : messages.SHOW_LEADERS)}
                />
              </button>
            ) }

          { hasLeaders && isLeadersPanelExpanded
            && (
              <div className={classNames.members}>
                { businessCommunityLeader
                  && (
                    <div className={classNames.member}>
                      <LeaderBubble
                        user={businessCommunityLeader}
                        title={<FormattedMessage {...messages.BUSINESS_COMMUNITY_LEADER} />}
                      />
                    </div>
                  ) }

                { digitalCommunityLeader
                  && (
                    <div className={classNames.member}>
                      <LeaderBubble
                        user={digitalCommunityLeader}
                        title={<FormattedMessage {...messages.DIGITAL_COMMUNITY_LEADER} />}
                      />
                    </div>
                  ) }
              </div>
            ) }
        </div>

        <div className={classNames.divider} />

        <div className={classNames.bottom}>
          <div>
            <div className={classNames.totalProductCount}>
              <FormattedHTMLMessage
                {...messages.TOTAL_PRODUCT_COUNT}
                values={{ count: totalProductCount }}
              />
            </div>
            <div className={classNames.domainsCount}>
              <FormattedMessage
                {...messages.DOMAIN_COUNT}
                values={{ count: domains?.length || 0 }}
              />
            </div>
          </div>

          { hasDomains
            && (
              <div className={classNames.arrow}>
                <button
                  type="button"
                  className={cn('unstyled with-pointer')}
                  onClick={onToggleDomainsPanel}
                  title={(isDomainsPanelExpanded ?
                    intl.formatMessage({ ...messages.HIDE_DOMAINS })
                    : intl.formatMessage({ ...messages.SHOW_DOMAINS }))}
                >
                  <Icon type={isDomainsPanelExpanded ? 'up-circle' : 'down-circle'} />
                </button>
              </div>
            ) }
        </div>

        { isDomainsPanelExpanded && hasDomains
          && (
            <>
              <ul className={classNames.domains}>
                { domains.map(domain => (
                  <DomainListItem
                    key={domain.id}
                    domain={domain}
                    onClickGetProducts={onClickDomain}
                  />
                )) }
              </ul>

              <div className={classNames.buttonContainer}>
                <Button
                  outline
                  overrideClassName={classNames.communityProductsButton}
                  overrideClassNames={classNames}
                  onClick={this.onClick}
                >
                  <FormattedMessage {...messages.SEE_COMMUNITY_PRODUCTS} />
                </Button>
              </div>
            </>
          ) }
      </div>
    );
  }
}

export default enhance(CommunitiesElement);
