import { defineMessages } from 'react-intl';

export default defineMessages({
  SHOW_DOMAINS: { id: 'communities.card.tooltip.show_domains', defaultMessage: 'Show domains' },
  HIDE_DOMAINS: { id: 'communities.card.tooltip.hide_domains', defaultMessage: 'Hide domains' },
  SHOW_LEADERS: { id: 'communities.card.tooltip.show_leaders', defaultMessage: 'Show leaders' },
  HIDE_LEADERS: { id: 'communities.card.tooltip.hide_leaders', defaultMessage: 'Hide leaders' },
  BUSINESS_COMMUNITY_LEADER: { id: 'communities.card.user.business_leader', defaultMessage: 'Business leader ' },
  DIGITAL_COMMUNITY_LEADER: { id: 'communities.card.user.digital_leader', defaultMessage: 'Digital leader' },
  TOTAL_PRODUCT_COUNT: {
    id: 'communities.card.products.count',
    defaultMessage: '<div class="count">{count, number}</div> <div class="label">Platform {count, plural, one {product} other {products}}</div>',
  },
  DOMAIN_COUNT: {
    id: 'commmunities.card.domains.count',
    defaultMessage: 'In {count, number} {count, plural, one {domain} other {domains}}',
  },
  SEE_COMMUNITY_PRODUCTS: { id: 'communities.card.seeProducts', defaultMessage: 'See all products of this platform' },
});
