import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';

import { communityShape } from 'shapes/community';
import { BREAKPOINTS_MAP } from 'config/style';

import Masonry from 'react-masonry-css';
import CommunitiesElement from '../element/CommunitiesElement';

import classNames from './communities-list.module.scss';

// max-width => col count.
const breakpointsCol = {
  default: 4,
  [BREAKPOINTS_MAP.tiny]: 1,
  [BREAKPOINTS_MAP.small]: 1,
  [BREAKPOINTS_MAP.medium]: 2,
  [BREAKPOINTS_MAP.large]: 2,
  [BREAKPOINTS_MAP['extra-large']]: 4,
};

const enhancer = compose(
  memo,
);

const CommunitiesList = ({ communities, onClickCommunity, onClickDomain }) => (
  <Masonry
    breakpointCols={breakpointsCol}
    className={classNames.grid}
    columnClassName={classNames.column}
  >
    {communities.map(community => (
      <CommunitiesElement
        community={community}
        key={community.id}
        onClickCommunity={onClickCommunity}
        onClickDomain={onClickDomain}
        className={classNames.card}
      />
    ))}
  </Masonry>
);

CommunitiesList.displayName = 'CommunitiesList';

CommunitiesList.propTypes = {
  communities: PropTypes.arrayOf(communityShape),
  onClickCommunity: PropTypes.func,
  onClickDomain: PropTypes.func,
};

CommunitiesList.defaultProps = {
  communities: [],
  onClickCommunity: noop,
  onClickDomain: noop,
};

export default enhancer(CommunitiesList);
