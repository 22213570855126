import { noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

import { domainShape } from 'shapes/community';
import businessMessages from 'config/business.messages';

import SafeHtml from 'react/generic/html/SafeHtml';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from './domainListItem.module.scss';

export default class DomainListItem extends React.PureComponent {
  static propTypes = {
    domain: domainShape.isRequired,
    onClickGetProducts: PropTypes.func,
  };

  static defaultProps = {
    onClickGetProducts: noop,
  };

  onClickGetProducts = () => {
    this.props.onClickGetProducts(this.props.domain.id);
  };

  /** @returns {object} JSX. */
  render() {
    const { domain } = this.props;

    return (
      <li className={classNames.item}>
        <button
          type="button"
          className={cn(classNames.domainTitle, 'unstyled with-pointer')}
          onClick={this.onClickGetProducts}
        >
          {domain.name}
        </button>

        { domain?.description && (
          <Tooltip
            placement="top"
            title={<SafeHtml unsafeHtml={domain.description} />}
          >
            <span className={classNames.infoTooltip}>
              <Asset name={ICON_MAP.info} />
            </span>
          </Tooltip>
        )}

        <span className={classNames.count}>
          (
          <FormattedMessage
            {...businessMessages.PRODUCTS_COUNT}
            values={{ count: domain.productCount || 0 }}
          />
          )
        </span>
      </li>
    );
  }
}
